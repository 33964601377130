;(function($, document, window, undefined) {
  'use strict';

  var pluginName = 'hesTrust';

  var HESTrust = function(el, options) {
    this.VERSION = '1.0.0';
    this.options = $.extend({}, options);

    this.cache = {};
    this.cache.el = el;
    this.cache.$list = $('.trust__list', el);
    this.cache.$items = $('.trust__item', el);

    this.init();
  };

  HESTrust.prototype = {
    init: function() {
      //this.applyWidth();
    },

    applyWidth: function() {
      var width = 0;

      $.each(this.cache.$items, function() {
        width += this.offsetWidth + 60;
      });

      this.cache.$list.width(width);
    }
  };

  $.fn[pluginName] = function(options) {
    return this.each(function() {
      if(!$.data(this, pluginName)) {
        $.data(this, pluginName, new HESTrust(this, options));
      }
    });
  };

})(jQuery, document, window);
