$(function() {
  $('.header').hesHeader();
  $('.tabs').hesTabs();
  $('.hero').hesHero();
  $('.map').hesMap();
  $('.vacancy').hesVacancy();
  $('.trust').hesTrust();

  var $promo = $('.promo');
  if($('.promo__item', $promo).length > 1) {
    $promo.addClass('owl-carousel').owlCarousel({
      items: 1,
      loop: true,
      nav: false,
      autoplay: true,
      autoplayHoverPause: true
    });
  }

  $('input').iCheck({
    checkboxClass: 'form__checkbox',
    radioClass: 'form__radio'
  });
  $('select').select2();
  $('[name="product"]').select2({
    placeholder: 'Выберите продукт*'
  });
  $('.trust__list').slick({
    autoplay: true,
    autoplaySpeed: 0,
    arrows: false,
    centerMode: true,
    centerPadding: 0,
    draggable: false,
    mobileFirst: true,
    slidesToShow: 5,
    speed: 4000,
    useCSS: false,
    variableWidth: true
  });

  $('.carousel__list').slick({
    adaptiveHeight: true,
    arrows: false,
    autoplay: true,
    dots: true,
    mobileFirst: true,
    infinite: true,
    responsive: [
      {
        breakpoint: 1279,
        settings: {
          arrows: true
        }
      }
    ],
    slidesToShow: 1
  });
});
