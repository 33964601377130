;(function($, document, window, undefined) {
  'use strict';

  var instance = 0;

  var HESVacancy = function(el, options, instance) {
    this.VERSION = '1.0.0';
    this.instance = instance;
    this.index = 0;

    this.options = $.extend({}, options);

    // Cache me!
    this.cache = {
      $window: $(window),
      $document: $(document),
      $body: $(document.body),
      el: el,
      $el: $(el)
    };

    this.init();
  };

  HESVacancy.prototype = {
    /**
     * Plugin initialisation
     */
    init: function() {
      this.bindEvents();
    },

    bindEvents: function() {
      $('.vacancy__title').on('click', this.toggleHandler.bind(this));
    },

    toggleHandler: function(e) {
      //e.preventDefault();
      $(e.currentTarget.parentElement).toggleClass('vacancy__item_current');
    }

  };

  $.fn.hesVacancy = function(options) {
    return this.each(function() {
      if(!$.data(this, 'hesVacancy')) {
        $.data(this, 'hesVacancy', new HESVacancy(this, options, instance++));
      }
    });
  };

})(jQuery, document, window);
