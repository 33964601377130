;
// Is in Viewport Check
window.isInViewport = (function(document, window, undefined) {
  'use strict';

  var isInViewport = function(element) {
    var rect = element.getBoundingClientRect();
    var html = document.documentElement;

    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || html.clientHeight) &&
      rect.right <= (window.innerWidth || html.clientWidth)
    );
  };

  return isInViewport;
})(document, window);

window.transitionEvent = (function(document, undefined) {
  'use strict';

  var whichTransitionEvent = function() {
    var t;
    var el = document.createElement('fakeelement');
    var transitions = {
      'transition': 'transitionend',
      'OTransition': 'oTransitionEnd',
      'MozTransition': 'transitionend',
      'WebkitTransition': 'webkitTransitionEnd'
    };

    for(t in transitions){
      if(el.style[t] !== undefined){
        return transitions[t];
      }
    }
  };

  return whichTransitionEvent();
})(document);
