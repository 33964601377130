;(function($, document, window, undefined) {
  'use strict';

  var instance = 0;

  var HESTabs = function(el, options, instance) {
    this.VERSION = '1.0.0';
    this.instance = instance;
    this.index = 0;

    this.options = $.extend({
      tabsItem: '.tabs__item',
      tabsActiveClass: 'tabs__item_state_current',
      contentItem: '.tabs__content',
      contentActiveClass: 'tabs__content_state_current'
    }, options);

    // Cache me!
    this.cache = {
      $window: $(window),
      $document: $(document),
      $body: $(document.body),
      el: el,
      $el: $(el)
    };

    this.init();
  };

  HESTabs.prototype = {
    /**
     * Plugin initialisation
     */
    init: function() {
      this.bindEvents();
    },

    /**
     * Event binding
     */
    bindEvents: function() {
      $(this.options.tabsItem).on('click', this.cache.$el, this.tabEvent.bind(this));
    },

    /**
     * Tab event handler
     * @param e
     */
    tabEvent: function(e) {
      e.preventDefault();

      var item  = e.currentTarget;
      var headerHeight = window.innerWidth < 640 || window.innerWidth > 767 ? 77 : 154;

      if(this.cache.$el.hasClass('tabs_type_alt')) {
        $('html, body').animate({
          scrollTop: $(e.currentTarget.children[0].hash).offset().top - headerHeight
        }, 1000);

        return;
      }

      var list = item.parentNode;
      var index = Array.prototype.indexOf.call(list.children, item);
      var $currentItem = $('.' + this.options.tabsActiveClass);

      if($currentItem.index() != index) {
        this.setIndex(index);
        this.enableTab(index);
      }
    },

    /**
     * Enable tab logic
     * @param index
     */
    enableTab: function(index) {
      var $tabs = $(this.options.tabsItem, this.cache.$el);
      var $currentTab = $tabs.eq(index);
      var $contentList = $(this.options.contentItem, this.cache.$el);
      var $currentContent = $contentList.eq(index);

      $tabs.removeClass(this.options.tabsActiveClass);
      $currentTab.addClass(this.options.tabsActiveClass);
      $contentList.removeClass(this.options.contentActiveClass);
      $currentContent.addClass(this.options.contentActiveClass);
    },

    /**
     * Set tab index as current
     * @param index
     */
    setIndex: function(index) {
      this.index = index;
    },

    /**
     * Get current tab index
     * @returns {number|*}
     */
    getIndex: function() {
      return this.index;
    }
  };

  $.fn.hesTabs = function(options) {
    return this.each(function() {
      if(!$.data(this, 'hesTabs')) {
        $.data(this, 'hesTabs', new HESTabs(this, options, instance++));
      }
    });
  };

})(jQuery, document, window);
