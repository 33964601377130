;(function($, document, window, undefined) {
  'use strict';

  var HESHeader = function(el, options) {
    this.VERSION = '1.0.0';
    this.options = $.extend({
      openClass: 'js-navigation-on',
      hoverClass: 'js-navigation-hover',
      currentItemClass: 'navigation__item_current',
      headerLinkHoverClass: 'header__link_hover',
      toggleActiveClass: 'navigation__item_active'
    }, options);
    this.cache = {
      $navigation: $('.navigation', el),
      $navigationItem: $('.navigation__item', el),
      $headerLink: $('.header__link', el),
      dropdownTimeout: undefined
    };

    this.init();
  };

  HESHeader.prototype = {
    /**
     * Plugin initialisation
     */
    init: function() {
      $('.header__hamburger').on('click', this.hamburgerClickHandler.bind(this));
      $('span.navigation__link, .navigation__toggle').on('click', this.toggleClickHandler.bind(this));
      $('.header__link_toggle').on('mouseenter mouseleave', this.toggleHoverHandler.bind(this));
      $('.header__toggle').on('click', this.toggleTouchClickHandler.bind(this));
      $('.header__dropdown').on('mouseenter mouseleave', this.toggleDropdownHoverHandler.bind(this));
      $(window).on({
        'resize': this.windowResizeHandler.bind(this),
        'orientationchange': this.windowRotateHandler.bind(this)
      });
    },

    hamburgerClickHandler: function(e) {
      e.preventDefault();
      if($(document.body).hasClass(this.options.openClass)) {
        this.closeNavigation();
      } else {
        this.openNavigation();
      }
    },

    toggleClickHandler: function(e) {
      e.preventDefault();
      e.stopPropagation();

      var $item = $(e.currentTarget).parents('.navigation__item');

      if($item.hasClass(this.options.toggleActiveClass)) {
        this.closeToggle($item);
      } else {
        this.openToggle($item);
      }
    },

    windowResizeHandler: function(e) {
      if(window.innerWidth > 1024) {
        this.makeEverythingPerfect();
      }
    },

    windowRotateHandler: function() {
      this.makeEverythingPerfect();
    },

    makeEverythingPerfect: function() {
      var self = this;

      clearTimeout(this.cache.dropdownTimeout);
      this.closeNavigation();
      $.each($('.navigation__item'), function() {
        self.closeToggle($(this));
      });
    },

    closeNavigation: function() {
      var self = this;

      $(document.body).removeClass(this.options.openClass);
      setTimeout(function() {
        self.cache.$navigation.removeAttr('style');
      }, 400);
    },

    openNavigation: function() {
      var self = this;

      this.cache.$navigation.css('height', window.innerHeight - 75);
      setTimeout(function() {
        $(document.body).addClass(self.options.openClass);
      }, 400);
    },

    openToggle: function($item) {
      var $dropdown = $('.dropdown', $item);
      var $dropdownItem = $('.dropdown__item', $dropdown);
      var dropdownHeight = 0;

      $item.addClass(this.options.toggleActiveClass);
      $.each($dropdownItem, function() {
        dropdownHeight += $(this).outerHeight();
      });

      $dropdown.css('max-height', dropdownHeight);
    },

    closeToggle: function($item) {
      var $dropdown = $('.dropdown', $item);

      $item.removeClass(this.options.toggleActiveClass);
      $dropdown.removeAttr('style');
    },

    toggleHoverHandler: function(e) {
      e.stopPropagation();

      var self = this;

      if(window.innerWidth > 1024) {
        if(e.type == 'mouseenter') {
          var $headerLink = $(e.currentTarget);
          var $siblings = $headerLink.parent().siblings();

          clearTimeout(this.cache.dropdownTimeout);
          $headerLink.addClass(this.options.headerLinkHoverClass);
          $.each($siblings, function() {
            $(this).find('.' + self.options.headerLinkHoverClass).removeClass(self.options.headerLinkHoverClass);
          });
          $(document.body).addClass(this.options.hoverClass);
          this.cache.$navigationItem.removeClass(this.options.currentItemClass).eq($headerLink.parent().index()).addClass(this.options.currentItemClass);
        } else {
          this.hideDropdown();
        }
      }
    },

    toggleTouchClickHandler: function(e) {
      e.stopPropagation();
      e.preventDefault();

      var self = this;
      var $headerLink = $(e.currentTarget).siblings('a').eq(0);

      if(!$headerLink.hasClass(this.options.headerLinkHoverClass)) {
        var $siblings = $headerLink.parent().siblings();

        clearTimeout(this.cache.dropdownTimeout);
        $headerLink.addClass(this.options.headerLinkHoverClass);
        $.each($siblings, function() {
          $(this).find('.' + self.options.headerLinkHoverClass).removeClass(self.options.headerLinkHoverClass);
        });
        $(document.body).addClass(this.options.hoverClass);
        this.cache.$navigationItem.removeClass(this.options.currentItemClass).eq($headerLink.parent().index()).addClass(this.options.currentItemClass);
      } else {
        $(document.body).removeClass(self.options.hoverClass);
        $(self.cache.$headerLink).removeClass(self.options.headerLinkHoverClass);
      }
    },

    toggleDropdownHoverHandler: function(e) {
      if(e.type == 'mouseenter') {
        clearTimeout(this.cache.dropdownTimeout);
      } else {
        this.hideDropdown();
      }
    },

    hideDropdown: function() {
      var self = this;

      this.cache.dropdownTimeout = setTimeout(function() {
        $(document.body).removeClass(self.options.hoverClass);
        $(self.cache.$headerLink).removeClass(self.options.headerLinkHoverClass);
      }, 800);
    }
  };

  $.fn.hesHeader = function(options) {
    return this.each(function() {
      if(!$.data(this, 'hesHeader')) {
        $.data(this, 'hesHeader', new HESHeader(this, options));
      }
    });
  };

})(jQuery, document, window);
