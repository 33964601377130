;(function($, document, window, undefined) {
  'use strict';

  var pluginName = 'hesMap';
  var instance = 0;

  var HESMap = function(el, options, instance) {
    this.VERSION = '1.0.0';
    this.instance = instance;

    this.options = $.extend({
      map: {
        backgroundColor: '#0cc5dd',
        center: new google.maps.LatLng(54.7801339, 31.9999611),
        disableDefaultUI: true,
        minZoom: 4,
        scrollwheel: false,
        styles: [
          {
            featureType: 'landscape.natural',
            elementType: 'geometry.fill',
            stylers: [
              {
                color: '#d2f5f9'
              }
            ]
          },
          {
            featureType: 'water',
            elementType: 'geometry.fill',
            stylers: [
              {
                color: '#0cc5dd'
              }
            ]
          },
          {
            featureType: 'water',
            elementType: 'labels',
            stylers: [
              {
                visibility: 'off'
              }
            ]
          },
          {
            featureType: 'administrative',
            elementType: 'geometry.stroke',
            stylers: [
              {
                color: '#65b0bb'
              }
            ]
          },
          {
            featureType: 'administrative',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#0e3035'
              }
            ]
          },
          {
            featureType: 'administrative',
            elementType: 'labels.text.stroke',
            stylers: [
              {
                color: '#fff'
              }
            ]
          },
          {
            featureType: 'road',
            elementType: 'geometry',
            stylers: [
              {
                saturation: -100
              },
              {
                lightness: 50
              },
              {
                visibility: 'simplifed'
              }
            ]
          }
        ],
        zoom: 4,
        zoomControl: true,
        zoomControlOptions: {
          position: google.maps.ControlPosition.TOP_RIGHT,
          style: google.maps.ZoomControlStyle.LARGE
        }
      },
      pin: {
        url: '/wp-content/themes/hes/images/map__pin.png',
        anchor: new google.maps.Point(19, 19)
      },
      data: [
        {
          position: {
            lat: 53.926024,
            lng: 27.614938
          },
          content: '' +
          '<div class="map__title">Беларусь</div>' +
          '<div class="map__address">220114, бульвар Толбухина, д. 2, БЦ «Тайм»</div>'
        },
        {
          position: {
            lat: 55.7401843,
            lng: 37.5086761
          },
          content: '' +
          '<div class="map__title">Россия</div>' +
          '<div class="map__address">101000, Москва, ул. Барклая, д. 6, стр. 9, БЦ «Барклай Плаза»</div>'
        }
      ]
    }, options);

    this.cache = {
      el: el
    };

    this.markers = [];

    this.init();
  };

  HESMap.prototype = {
    init: function() {
      this.cache.map = new google.maps.Map(this.cache.el, this.options.map);

      this.initMarkers();
      this.initInfoWindows();
    },

    initInfoWindows: function() {
      var map = this.cache.map;
      var event = this.markerEvent;
      var data = this.options.data;

      $.each(this.markers, function(i) {
        var infoWindow = new google.maps.InfoWindow({
          disableAutoPan: true,
          pixelOffset: new google.maps.Size(0, 0),
          content: data[i].content,
          maxWidth: 250
        });

        google.maps.event.addListener(this, 'click', event.bind(this, infoWindow, map));
      });
    },

    initMarkers: function() {
      var data = this.options.data;

      for(var i = 0; i < data.length; i++) {
        var item = data[i];
        var marker = new google.maps.Marker({
          animation: google.maps.Animation.DROP,
          content: item.content,
          icon: this.options.pin,
          map: this.cache.map,
          position: new google.maps.LatLng(item.position.lat, item.position.lng)
        });

        this.markers.push(marker);
      }
    },

    markerEvent: function(infoWindow, map) {
      infoWindow.open(map, this);
    }
  };

  $.fn[pluginName] = function(options) {
    return this.each(function() {
      if(!$.data(this, pluginName)) {
        $.data(this, pluginName, new HESMap(this, options, instance++));
      }
    });
  };

})(jQuery, document, window);
