;(function($, document, window, undefined) {
  'use strict';

  var instance = 0;

  var HESHero = function(el, options, instance) {
    this.VERSION = '1.0.0';
    this.instance = instance;
    this.lvl1 = 0;
    this.lvl2 = 0;

    this.options = $.extend({
      heroItem: '.hero__item',
      heroItemLVL1: '.hero__item_lvl_1',
      heroItemLVL2: '.hero__item_lvl_2',
      heroItemCalculatedClass: 'hero__item_calculated',
      heroItemCurrentClass: 'hero__item_current',
      heroItemAnimationInClass: 'hero__item_animation_in',
      heroItemAnimationOutClass: 'hero__item_animation_out',
      heroArea: '.hero__area',
      heroNext: '.hero__control_next',
      heroPrev: '.hero__control_prev',
      heroControlSplashClass: 'hero__control_splash',
      heroNavigation: '.hero__navigation',
      heroNavigationDismissClass: 'hero__navigation_dismiss'
    }, options);

    this.cache = {
      $window: $(window),
      $document: $(document),
      $body: $(document.body),
      el: el,
      $el: $(el),
      $heroItem: $(this.options.heroItem),
      $heroItemLVL1: $(this.options.heroItemLVL1),
      $heroItemLVL2: $(this.options.heroItemLVL2),
      $heroArea: $(this.options.heroArea),
      $heroNext: $(this.options.heroNext),
      $heroPrev: $(this.options.heroPrev),
      $heroNavigation: $(this.options.heroNavigation)
    };

    this.init();
  };

  HESHero.prototype = {
    /**
     * Plugin initialisation
     */
    init: function() {
      var temp = [];

      setTimeout(this.prepareCarousel.bind(this), 500);
      this.bindEvents();
      $.each(this.cache.$heroItemLVL1, function() {
        temp.push(this.children[0].childElementCount - 1);
      });
      this.setNavigationArray(temp);

      //$(window).on('resize', this.resizeEvent.bind(this));
    },

    resizeEvent: function() {},

    bindEvents: function() {
      this.cache.$heroNext.on('click', this.nextEvent.bind(this));
      this.cache.$heroPrev.on('click', this.prevEvent.bind(this));
      $('.hero__control').on(transitionEvent, function(e) {
        e.stopPropagation();
      });
      $('.hero__arrow').on(transitionEvent, function(e) {
        e.stopPropagation();
      });
    },

    unbindEvents: function() {
      this.cache.$heroNext.off('click');
      this.cache.$heroPrev.off('click');
    },

    setNavigationArray: function(array) {
      this.tree = array;
    },

    getNavigationArray: function() {
      return this.tree;
    },

    increaseCounter: function(type) {
      type === 'group' ? this.lvl1++ : this.lvl2++;
    },

    reduceCounter: function(type) {
      type === 'group' ? this.lvl1-- : this.lvl2--;
    },

    setCounter: function(type, position) {
      type === 'group' ? this.lvl1 = position : this.lvl2 = position;
    },

    getCounter: function(type) {
      return type === 'group' ? this.lvl1 : this.lvl2;
    },

    setAnimationType: function(type) {
      this.animationType = type;
    },

    getAnimationType: function() {
      return this.animationType;
    },

    changeView: function(e) {
      var currentItem = this.getCurrentItem();
      var $group = this.cache.$heroItemLVL1.eq(currentItem[0]);
      var $item = $(this.options.heroItemLVL2, $group).eq(currentItem[1]);
      var $subtitle = $('.hero__subtitle', $item);
      var animationType = this.getAnimationType();
      var item = [];

      item.push(this.getCounter('group'));
      item.push(this.getCounter('item'));
      if(animationType === 'item') {
        console.log('change item animation'); // TODO: Remove
        this.hideItem();
        $subtitle.one(transitionEvent + '.queue-show', function() {
          this.changeHeight();
          this.showItem(item);
          this.setCurrentItem(item);
          this.bindEvents();
        }.bind(this));
      } else {
        var $control = $(e.currentTarget);

        console.log('change group animation'); // TODO: Remove
        this.doSplash($control);
        $control.one(transitionEvent + '.queue-show', function(e) {
          e.stopPropagation();
          this.hideItem();
          this.hideGroup();
          this.showGroup(item, $control);
          this.setCurrentItem(item);
          this.bindEvents();
        }.bind(this));
      }
    },

    doSplash: function($item) {
      $item.addClass(this.options.heroControlSplashClass);
    },

    nextEvent: function(e) {
      var tree = this.getNavigationArray();

      this.unbindEvents();
      if(this.lvl2 < tree[this.lvl1]) {
        this.increaseCounter('item');
        this.setAnimationType('item');
      } else if(this.lvl1 < tree.length - 1) {
        this.increaseCounter('group');
        this.setAnimationType('group');
        this.setCounter('item', 0);
      } else {
        this.setAnimationType('group');
        this.setCounter('group', 0);
        this.setCounter('item', 0);
      }
      this.changeView(e);
    },

    prevEvent: function(e) {
      var tree = this.getNavigationArray();

      this.unbindEvents();
      if(this.lvl2 !== 0) {
        this.reduceCounter('item');
        this.setAnimationType('item');
      } else if(this.lvl1 !== 0) {
        this.reduceCounter('group');
        this.setAnimationType('group');
        this.setCounter('item', tree[this.lvl1]);
      } else {
        this.setAnimationType('group');
        this.setCounter('group', tree.length - 1);
        this.setCounter('item', tree[tree.length - 1]);
      }
      this.changeView(e);
    },

    /**
     * Prepare hero carousel
     */
    prepareCarousel: function() {
      var height = document.querySelector(this.options.heroItemLVL2).offsetHeight;
      var item = [this.lvl1, this.lvl2];

      this.setItemHeight(height);
      this.cache.$el.height(height);
      this.setCurrentItem(item);
      this.showItem(item);
      this.cache.$heroItem.addClass(this.options.heroItemCalculatedClass);
    },

    showItem: function(item) {
      var height = this.getItemHeight();
      var $group = this.cache.$heroItemLVL1.eq(item[0]);
      var $item = $(this.options.heroItemLVL2, $group).eq(item[1]);

      $group.height(height).addClass(this.options.heroItemCurrentClass);
      $item.addClass(this.options.heroItemCurrentClass + ' ' + this.options.heroItemAnimationInClass);
      this.cache.$heroArea.height(height);
    },

    hideItem: function() {
      var currentItem = this.getCurrentItem();
      var $group = this.cache.$heroItemLVL1.eq(currentItem[0]);
      var $item = $(this.options.heroItemLVL2, $group).eq(currentItem[1]);
      var $subtitle = $('.hero__subtitle', $item);

      $item.addClass(this.options.heroItemAnimationOutClass);
      $subtitle.one(transitionEvent + '.queue-dismiss', function() {
        $item.removeClass(this.options.heroItemCurrentClass + ' ' + this.options.heroItemAnimationInClass + ' ' + this.options.heroItemAnimationOutClass);
      }.bind(this));
    },

    showGroup: function(item, $control) {
      var $group = this.cache.$heroItemLVL1.eq(item[0]);
      var $nav = this.cache.$heroNavigation;

      $group.addClass(this.options.heroItemCurrentClass);
      $nav.addClass(this.options.heroNavigationDismissClass);
      $nav.one(transitionEvent, function() {

        console.log('...'); // TODO: Remove

        this.showItem(item);
        $control.removeClass(this.options.heroControlSplashClass);
        $control.one(transitionEvent + '.queue-control', function() {
          $nav.removeClass(this.options.heroNavigationDismissClass);
        }.bind(this));
      }.bind(this));
    },

    hideGroup: function() {
      var currentItem = this.getCurrentItem();
      var $group = this.cache.$heroItemLVL1.eq(currentItem[0]);

      $group.removeClass(this.options.heroItemCurrentClass);
    },

    changeHeight: function() {

      console.log('changeHeight'); // TODO: Remove

    },

    setCurrentItem: function(item) {
      this.currentItem = item;
    },

    getCurrentItem: function() {
      return this.currentItem;
    },

    setItemHeight: function(height) {
      this.options.heroItemHeight = height;
    },

    getItemHeight: function() {
      return this.options.heroItemHeight;
    }
  };

  $.fn.hesHero = function(options) {
    return this.each(function() {
      if(!$.data(this, 'hesHero')) {
        $.data(this, 'hesHero', new HESHero(this, options, instance++));
      }
    });
  };

})(jQuery, document, window);
